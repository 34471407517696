import md5 from 'md5'

export function addOggExtension(text: string) {
  if (!text.endsWith('.ogg')) text = `${text}.ogg`
  return text
}

export function removeOggExtension(text: string) {
  return text.replace('.ogg', '')
}

export function toParticle(serial: string, date: string, name: string): string {
  return `${serial}/${date}/${removeOggExtension(name)}`
}

export function getSoundKey(
  serial: string,
  date: string,
  name: string,
): string {
  const p = toParticle(serial, date, name)
  return `${md5(p)}_${p.replace(/\//g, '-')}`
}
