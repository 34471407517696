import {
  LabelKey,
  getLabelsGroupConfigFromTitle,
  labelsPaginationMap,
} from 'common/ontology'
import { addOggExtension, toParticle } from 'common/soundKey'
import {
  AddSoundsToLabelingQueueParameters,
  HardLabel,
  LabelsPagination,
  SoftLabel,
  Sound,
  SoundLabels,
  Sounds,
} from 'common/types'
import { ref } from 'firebase/database'
import { useCallback, useEffect, useState } from 'react'
import { QueueEntry, markEntryAsLabeled, useQueue } from 'shared/hooks/useQueue'
import { dataState, loadingState } from 'shared/types/asyncState'
import { FirebaseKey } from 'shared/types/utils'
import { fetch_ } from 'shared/utils/fetch'
import { auth, database } from '../firebase'
import { remove, set, update } from '../firebaseMethods'

const SOUND_FROM_URL_PRIORITY = 1
const SOUND_FROM_URL_LABELERS_TARGET = 3

function dumpLabeling(
  labelsPagination: LabelsPagination,
  labels: Partial<Record<LabelKey, LabelKey[]>>,
  labelsProposals: string[],
  hardLabels: HardLabel[],
) {
  let formatedLabels: SoftLabel[] = []

  for (const [labelsGroupTitle, subLabels] of Object.entries(labels) as [
    LabelKey,
    LabelKey[],
  ][]) {
    const labelGroup = getLabelsGroupConfigFromTitle(
      labelsPagination,
      labelsGroupTitle,
    )
    if (!labelGroup.isNotLabel) {
      // isLabel
      formatedLabels.push({ label: labelGroup.title })
    }
    for (const subLabel of subLabels) {
      formatedLabels.push({ label: `${labelGroup.title}.${subLabel}` })
    }
  }

  for (const labelProposal of labelsProposals) {
    formatedLabels.push({ label: `#${labelProposal}` })
  }

  formatedLabels = [...formatedLabels, ...hardLabels]

  formatedLabels = formatedLabels.sort((labelA, labelB) => {
    return labelA.label.localeCompare(labelB.label)
  })

  return formatedLabels
}

interface SoundFromURL {
  sound: Sound
  soundKey: FirebaseKey
}

async function getSoundFromURL() {
  const urlParameter = window.location.pathname.replace(/^\//, '')
  const parts = urlParameter.split('/')

  if (parts.length !== 3) return null
  const serial = parts[0]
  const date = parts[1]
  let name = parts[2]

  if (!serial || !date || !name) return null

  name = addOggExtension(name)

  const userEmail = auth.currentUser?.email

  if (!userEmail) {
    throw Error('Unknown user')
  }

  const requestBody: AddSoundsToLabelingQueueParameters = [
    {
      particles: [toParticle(serial, date, name)],
      source: `link-${userEmail}`, // Warning, hard coded in functions
      why: `unknown`,
      priority: SOUND_FROM_URL_PRIORITY,
      labelersTarget: SOUND_FROM_URL_LABELERS_TARGET,
    },
  ]

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  }

  const sounds = await fetch_(
    `https://europe-west1-${
      import.meta.env.VITE_PROJECT_ID
    }.cloudfunctions.net/addSoundsToLabelingQueue`,
    options,
  )
    .then((response) => response.json())
    .then((result) => result as Sounds)

  const soundKey = Object.keys(sounds)[0]
  const sound = sounds[soundKey]

  return { sound, soundKey }
}

// Warning, must be defined out of useSound to provide a stable reference to useQueue
// Otherwise results in an infinite re-render loop
const soundsRef = ref(database, 'sounds')

export const useSounds = () => {
  const userId = auth.currentUser?.uid

  if (!userId) {
    throw Error('Unknown user')
  }

  // undefined = no yet set
  // null = no valid data found in URL
  const [soundFromURL, setSoundFromURL] = useState<
    SoundFromURL | null | undefined
  >(undefined)

  const queue = useQueue<Sound>(userId, soundsRef)

  const [previousSounds, setPreviousSounds] = useState<QueueEntry<Sound>[]>([])
  const [previousSoundIndex, setPreviousSoundIndex] = useState<number>(0)

  const [startTime, setStartTime] = useState(0)

  const labelsPagination = labelsPaginationMap[import.meta.env.VITE_ONTOLOGY]

  let currentSound: QueueEntry<Sound> | undefined = undefined

  // Wait for URL to be parsed
  if (soundFromURL !== undefined) {
    // In case a URL is in use
    if (soundFromURL) {
      currentSound = { key: soundFromURL.soundKey, value: soundFromURL.sound }
    } else if (previousSoundIndex > 0) {
      currentSound = previousSounds[previousSoundIndex - 1]
    } else {
      if (!queue.loading && queue.data !== null) {
        currentSound = queue.data.head
      }
    }
  }

  // Trigger a single url parsing on start
  useEffect(() => {
    async function runEffect() {
      const soundFromURL = await getSoundFromURL()
      setSoundFromURL(soundFromURL)
    }

    runEffect()
  }, [])

  // Re-init start time on each index change
  useEffect(() => {
    setStartTime(Date.now())
  }, [currentSound?.key])

  const prev = useCallback(() => {
    setPreviousSoundIndex((previousSoundIndex) => {
      return previousSoundIndex + 1
    })
  }, [])

  const next = useCallback(() => {
    setPreviousSoundIndex((previousSoundIndex) => {
      return previousSoundIndex - 1
    })
  }, [])

  const saveLabels = useCallback(
    async (
      labels: Partial<Record<LabelKey, LabelKey[]>>,
      labelsProposals: string[],
      hardLabels: HardLabel[],
      iAmSure: boolean,
    ): Promise<void> => {
      if (!currentSound) throw Error('saveLabels called with no sound')

      const key = currentSound.key
      const { serial, date, name, source, why } = currentSound.value

      const soundLabels: SoundLabels = {
        ts: Date.now(), // to keep it consistent with startTime which is evaluated locally
        start_ts: startTime,
        labels: dumpLabeling(
          labelsPagination,
          labels,
          labelsProposals,
          hardLabels,
        ),
        iAmSure,
      }

      // Copy data to export from 'sounds' to 'labeledSounds', needed even if it's not the first labeling
      const labeledSound = {
        serial,
        date,
        name,
        source,
        why,
      }
      await update(`labeledSounds/${key}`, labeledSound)

      await set(`labeledSounds/${key}/labelers/${userId}`, soundLabels)

      await markEntryAsLabeled(
        soundsRef,
        currentSound.key,
        userId,
        () => {
          return Promise.all([
            remove(`sounds/${currentSound.key}`),
            set(`soundsToBeExported/${key}`, true as const),
          ])
        },
        iAmSure,
      )

      if (previousSoundIndex === 0) {
        // After labeling a sound from queue or URL
        setPreviousSounds([currentSound, ...previousSounds])
        // Trigger selection of a new sound
        if (soundFromURL) setSoundFromURL(null)
        else {
          if (queue.loading || queue.data === null) return
          queue.data.pop()
        }
      } else {
        // After editing a previous labeling
        next()
      }
    },
    [
      currentSound,
      startTime,
      labelsPagination,
      userId,
      previousSounds,
      soundFromURL,
      previousSoundIndex,
      next,
      queue.loading,
      queue.data,
    ],
  )

  type UseSound = {
    particle: string
    serial: string
    date: string
    name: string
    saveLabels: typeof saveLabels
    prevDisabled: boolean
    nextDisabled: boolean
    prev: () => void
    next: () => void
  } | null

  if (soundFromURL === undefined) return loadingState()

  if (currentSound) {
    const { serial, date, name } = currentSound.value

    return dataState<UseSound>({
      particle: toParticle(serial, date, name),
      serial,
      date,
      name,
      saveLabels,
      prevDisabled: previousSoundIndex === previousSounds.length,
      nextDisabled: previousSoundIndex === 0,
      prev,
      next,
    })
  }

  if (queue.loading) return loadingState()
  return dataState<UseSound>(null)
}
